const data = [
      {
        name: "Dr. Bhaskar Bhowmik",
        position: "",
        imageUrl: "/assets/images/members/Dr. Bhaskar Bhowmik.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    },
    {
        name: "Prof. Damodar Maiti",
        position: "",
        imageUrl: "/assets/images/members/Prof. Damodar Maiti.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    },
    {
        name: "Prof. D.K. Maiti",
        position: "",
        imageUrl: "/assets/images/members/Prof. D.K. Maiti.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    },{
        name: "Prof. Goutam Sen",
        position: "",
        imageUrl: "/assets/images/members/gsen.png",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    },
    {
        name: "Prof. Santanu Roy",
        position: "",
        imageUrl: "/assets/images/members/Santanu Roy.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    },{
        name: "Prof. H.R. Tiwari",
        position: "",
        imageUrl: "/images/profile.png",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    }
    // {
    //     name: "Prof. Adrijit Goswami",
    //     position: "",
    //     imageUrl: "/assets/images/members/Prof. Adrijit Goswami.jpg",
    //     facebookLink: "https://www.facebook.com/gyws.iitkgp",
    //     linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    // },
    // {
    //     name: "Prof. Amit Patra",
    //     position: "",
    //     imageUrl: "/assets/images/members/Prof. Amit Patra.jpg",
    //     facebookLink: "https://www.facebook.com/gyws.iitkgp",
    //     linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    // },

    // {
    //     name: "Prof. Dilip Swain",
    //     position: "",
    //     imageUrl: "/assets/images/members/Prof. Dilip Swain.jpg",
    //     facebookLink: "https://www.facebook.com/gyws.iitkgp",
    //     linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    // },
    // {
    //     name: "Retd. Prof. H.R. Tiwari",
    //     position: "",
    //     imageUrl: "/images/profile.png",
    //     facebookLink: "https://www.facebook.com/gyws.iitkgp",
    //     linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    // },
    // {
    //     name: "Prof. Sasmita Mohakud",
    //     position: "",
    //     imageUrl: "/images/profile.png",
    //     facebookLink: "https://www.facebook.com/gyws.iitkgp",
    //     linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    // },
    // {
    //     name: "Prof. Sudipta Datta",
    //     position: "",
    //     imageUrl: "/images/profile.png",
    //     facebookLink: "https://www.facebook.com/gyws.iitkgp",
    //     linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    // },
    // {
    //     name: "Prof. Sudipto Chakraborty",
    //     position: "",
    //     imageUrl: "/assets/images/members/Prof. Sudipto Chakraborty.jpg",
    //     facebookLink: "https://www.facebook.com/gyws.iitkgp",
    //     linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    // },

]

export default data;


// Damodar maity 1
// Ddk maiti  1
// goutam sen 1
// dipak kumar maiti 
// Santanu sir 1
// hr tiwari 1
// bhaskar bhowmik 1